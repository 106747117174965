* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html {
  width: 100%; }

body {
  background-color: #eee;
  color: #333;
  font-family: 'Helvetica', 'Tahoma';
  width: 100%;
  overflow-x: hidden; }

.loading {
  width: 100%;
  text-align: center;
  margin-top: 50%; }

.countdown {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 45vh;
  font-size: 20vh;
  line-height: 10vh;
  color: #ccc; }

.auth .logo {
  width: 14vh;
  top: 0;
  left: calc(50% - 7vh);
  padding: 4vh 0 0 0; }

.auth .login {
  background-color: #fff;
  width: 30vh;
  padding: 2vh 2vh 1vh;
  margin: 30vh auto 0;
  position: relative; }
  .auth .login button {
    width: 100%;
    position: absolute;
    bottom: -4vh;
    left: 0;
    line-height: 4vh;
    border: 0;
    background-color: #2d2e82;
    color: #fff;
    font-size: 1.2vh;
    letter-spacing: 0.1vh;
    text-transform: uppercase;
    cursor: pointer; }
    .auth .login button:disabled {
      opacity: 0.5; }
  .auth .login label {
    display: block;
    font-size: 1.4vh;
    line-height: 3vh;
    color: #333; }
  .auth .login input {
    margin-bottom: 2vh;
    width: 100%;
    line-height: 3vh;
    border: 0.1vh solid #bbb;
    padding: 0 1vh;
    font-size: 1.4vh;
    color: #333; }

.logo {
  width: 24vh;
  background-color: #2d2e82;
  position: absolute;
  right: 0;
  bottom: 2vh;
  padding-right: 12vh; }

.clock {
  position: absolute;
  right: 0;
  top: 2vh;
  font-size: 3.2vh;
  line-height: 4vh;
  padding: 2vh;
  width: 24vh;
  text-align: center;
  background-color: #2d2e82;
  color: #fff; }
  .clock .extra-time {
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    bottom: -0.5vh;
    left: 6vh;
    width: 12vh;
    font-size: 1.2vh;
    line-height: 2vh;
    padding: 0 2vh;
    border-radius: 0.5vh; }

.scoreboard {
  position: absolute;
  right: 0;
  top: 12vh;
  width: 24vh;
  padding: 2vh;
  background-color: #ddd; }
  .scoreboard h1 {
    font-size: 2vh;
    margin-bottom: 2vh; }
  .scoreboard .team {
    text-transform: uppercase;
    font-size: 1.2vh;
    line-height: 3vh; }
    .scoreboard .team .flag {
      margin-right: 1vh; }
    .scoreboard .team .ranking {
      float: left;
      width: 2vh;
      height: 2vh; }
    .scoreboard .team .score {
      float: right;
      letter-spacing: 0.1vh; }

.tracks {
  width: 12vh;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%; }
  .tracks .track {
    width: 8vh;
    height: 8vh;
    border-radius: 1vh;
    text-align: center;
    padding: 2.5vh;
    margin: 2vh;
    cursor: pointer;
    background-color: #bbb; }
    .tracks .track .notification {
      position: absolute;
      top: -0.5vh;
      right: -0.5vh;
      background-color: #f14f4f;
      color: #fff;
      border-radius: 1.5vh;
      width: 3vh;
      height: 3vh;
      line-height: 3.2vh;
      font-size: 1.4vh;
      font-weight: bold; }
    .tracks .track:hover {
      background-color: #333; }
    .tracks .track.bottom {
      position: absolute;
      bottom: 0; }
    .tracks .track .svg-inline--fa {
      font-size: 3vh;
      color: #fff; }

.assignments {
  position: absolute;
  top: 2vh;
  left: 12vh;
  background-color: #fff;
  width: calc(100% - 42vh);
  padding: 2vh; }
  .assignments.description {
    background-color: transparent; }
  .assignments h1 {
    font-size: 2vh;
    margin-bottom: 2vh; }
  .assignments p {
    color: #999;
    line-height: 2vh;
    font-size: 1.4vh; }
  .assignments .assignment {
    padding: 0 2vh 0 7vh;
    background-color: #eee;
    border-radius: 0.5vh;
    margin-top: 1vh;
    text-transform: uppercase;
    font-size: 1.2vh;
    letter-spacing: 0.1vh;
    position: relative;
    line-height: 5vh;
    cursor: pointer; }
    .assignments .assignment .check {
      float: right;
      height: 5vh;
      font-size: 1.6vh; }
    .assignments .assignment.done {
      opacity: 0.5;
      cursor: default; }
    .assignments .assignment .order {
      position: absolute;
      width: 5vh;
      height: 5vh;
      left: 0;
      top: 0;
      background-color: #2d2e82;
      color: #fff;
      border-radius: 0.5vh;
      text-align: center;
      font-size: 1.4vh; }

.admin {
  width: 100%;
  padding: 2vh; }
  .admin .assignments {
    position: absolute;
    top: 2vh;
    left: 44vh;
    background-color: #fff;
    width: calc(100% - 74vh);
    padding: 2vh; }
    .admin .assignments .edit-button {
      float: right;
      cursor: pointer; }
  .admin .upsert {
    position: absolute;
    top: 2vh;
    left: 2vh;
    width: 40vh;
    background-color: #fff;
    padding: 2vh; }
    .admin .upsert label {
      margin-top: 2vh;
      margin-bottom: 0.5vh;
      text-transform: uppercase;
      color: #bbb;
      font-size: 1vh;
      display: block; }
    .admin .upsert input {
      width: 100%;
      line-height: 3vh;
      padding: 0 1vh;
      font-size: 1.3vh; }
    .admin .upsert textarea {
      width: 100%;
      padding: 1vh 1vh;
      font-size: 1.3vh;
      line-height: 2vh;
      font-family: Arial, Helvetica, sans-serif;
      border: 0.1vh solid #ddd;
      height: 14vh; }
    .admin .upsert select {
      width: 100%;
      line-height: 3vh;
      border-radius: 0.5vh;
      border: 0.1vh solid #ddd;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      padding: 0 0.5vh;
      outline: none;
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns%3D"http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width%3D"292.4" height%3D"292.4"%3E%3Cpath fill%3D"%23333333" d%3D"M287 69.4a17.6 17.6 0 0 0-13-5.4H18.4c-5 0-9.3 1.8-12.9 5.4A17.6 17.6 0 0 0 0 82.2c0 5 1.8 9.3 5.4 12.9l128 127.9c3.6 3.6 7.8 5.4 12.8 5.4s9.2-1.8 12.8-5.4L287 95c3.5-3.5 5.4-7.8 5.4-12.8 0-5-1.9-9.2-5.5-12.8z"%2F%3E%3C%2Fsvg%3E');
      background-repeat: no-repeat, repeat;
      background-position: right .7em top 50%, 0 0;
      background-size: .65em auto, 100%; }
  .admin button {
    background-color: #333;
    color: #fff;
    width: 100%;
    margin-top: 2vh;
    line-height: 3.8vh;
    border: 0;
    border-radius: 0.5vh;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 0.1vh; }
  .admin.logs {
    width: calc(100% - 24vh); }
    .admin.logs .log {
      background-color: #fff;
      border-radius: 0.5vh;
      margin-bottom: 1vh;
      display: block;
      padding: 1vh 1vh;
      line-height: 2vh;
      font-size: 1.4vh; }
    .admin.logs .team {
      width: 20vh;
      display: inline-block;
      margin: 0 1vh; }
    .admin.logs .assignment {
      margin: 0 1vh;
      display: inline-block;
      text-transform: uppercase;
      color: #999;
      font-size: 1.1vh; }
    .admin.logs .givenAnswer {
      display: inline-block;
      background-color: #990d0d;
      color: #fff;
      line-height: 2vh;
      font-size: 1.2vh;
      padding: 0 1vh;
      border-radius: 0.5vh;
      letter-spacing: 0.1vh;
      float: right; }
      .admin.logs .givenAnswer.correct {
        background-color: #617c2e; }
  .admin.menu a {
    display: block;
    padding: 1vh;
    margin-bottom: 1vh;
    background-color: #fff;
    color: #333;
    width: 20%;
    border-radius: 0.5vh;
    text-decoration: none; }
  .admin.menu textarea {
    padding: 1vh;
    border: 0;
    width: 40%;
    height: 10vh;
    margin-top: 4vh; }
  .admin.menu button {
    width: 40%;
    display: block; }
  .admin.teams {
    width: calc(100% - 24vh); }
    .admin.teams .team {
      background-color: #fff;
      border-radius: 0.5vh;
      margin-bottom: 1vh;
      display: block;
      padding: 1vh 1vh;
      line-height: 3vh;
      font-size: 1.6vh; }
      .admin.teams .team .team-score {
        float: right;
        background-color: #2d2e82;
        color: #fff;
        padding: 0 1vh;
        border-radius: 0.5vh; }
      .admin.teams .team .team-name, .admin.teams .team .team-phone {
        display: inline-block;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 0.1vh;
        font-size: 1.2vh; }
      .admin.teams .team .team-phone {
        font-weight: normal;
        margin-left: 4vh; }
      .admin.teams .team button {
        width: 10vh;
        margin: 0;
        background-color: transparent;
        border: 0.1vh solid #999;
        color: #999;
        line-height: 3vh;
        float: right;
        margin-right: 2vh; }
      .admin.teams .team .team-time {
        width: 10vh;
        margin: 0;
        background-color: #ccc;
        text-align: center;
        color: #fff;
        line-height: 2vh;
        float: right;
        padding: 0.5vh 0;
        margin-right: 2vh; }
      .admin.teams .team .team-calculated {
        width: 10vh;
        margin: 0;
        background-color: #617c2e;
        text-align: center;
        color: #fff;
        line-height: 2vh;
        float: right;
        padding: 0.5vh 0;
        margin-right: 2vh; }
  .admin.hints {
    width: calc(100% - 24vh); }
    .admin.hints .hint-request {
      height: 24vh;
      background-color: #fff;
      border-radius: 0.5vh;
      margin-bottom: 1vh;
      display: block;
      padding: 1vh 1vh;
      line-height: 2vh;
      font-size: 1.4vh; }
      .admin.hints .hint-request label {
        width: 10%;
        display: inline-block;
        line-height: 3vh; }
      .admin.hints .hint-request textarea {
        width: 40%;
        padding: 1vh 1vh;
        font-size: 1.3vh;
        line-height: 2vh;
        font-family: Arial, Helvetica, sans-serif;
        border: 0.1vh solid #ddd;
        height: 6vh; }
      .admin.hints .hint-request button {
        width: 40%;
        margin-top: 0; }

.assignment-full {
  width: 100%;
  height: 100%;
  background-color: #2d2e82;
  background-color: #e6e7e8;
  position: absolute;
  top: 0;
  left: 0;
  padding: 10vh; }
  .assignment-full.success {
    background-color: #617c2e !important;
    color: #fff; }
    .assignment-full.success h1, .assignment-full.success .question, .assignment-full.success .close {
      color: #fff; }
  .assignment-full.failed {
    background-color: #990d0d !important;
    color: #fff; }
    .assignment-full.failed h1, .assignment-full.failed .question, .assignment-full.failed .close {
      color: #fff; }
  .assignment-full.bonus {
    background-color: #2d2e82;
    color: #fff; }
    .assignment-full.bonus h1, .assignment-full.bonus .question, .assignment-full.bonus .close {
      color: #fff; }
  .assignment-full .close {
    color: #333;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 4vh;
    height: 10vh;
    width: 10vh !important;
    padding: 3vh;
    cursor: pointer; }
  .assignment-full ::-moz-selection {
    /* Code for Firefox */
    color: #c0c0e0;
    background: #22246e; }
  .assignment-full ::selection {
    color: #c0c0e0;
    background: #22246e; }
  .assignment-full .image {
    width: 64vh;
    height: 64vh;
    display: block;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    color: transparent;
    pointer-events: none; }
  .assignment-full .text {
    position: absolute;
    top: 10vh;
    left: 78vh;
    width: calc(100% - 8vh); }
  .assignment-full h1 {
    font-size: 3vh;
    color: #333;
    font-family: letter-gothic-std, monospace;
    font-weight: 400;
    font-style: normal; }
  .assignment-full .question {
    font-size: 3.6vh;
    color: #333;
    font-family: letter-gothic-std, monospace;
    font-weight: 700;
    font-style: normal;
    margin-top: 4vh;
    width: calc(100% - 79vh); }
  .assignment-full .answerfield {
    width: 59.2vh;
    background-color: rgba(255, 255, 255, 0.9);
    position: absolute;
    top: 76vh;
    left: 10vh;
    border: 0;
    line-height: 4.8vh;
    border-radius: 0.5vh;
    text-align: center;
    font-family: letter-gothic-std, monospace;
    font-weight: 700;
    font-style: normal;
    font-size: 3vh;
    padding: 0;
    text-transform: uppercase;
    transition: 0.3s background-color; }
    .assignment-full .answerfield:focus {
      background-color: #fff; }
    .assignment-full .answerfield::-moz-selection {
      /* Code for Firefox */
      color: #333;
      background: #ddd; }
    .assignment-full .answerfield::selection {
      color: #333;
      background: #ddd; }
  .assignment-full .submit {
    width: 4.8vh;
    height: 4.8vh;
    position: absolute;
    top: 76vh;
    left: 69.2vh;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    border-radius: 0.5vh;
    border: 0;
    cursor: pointer; }
    .assignment-full .submit svg {
      font-size: 2vh; }
  .assignment-full .direction {
    position: absolute;
    bottom: 10vh;
    font-family: letter-gothic-std, monospace;
    font-size: 1.4vh;
    opacity: 0.5; }
  .assignment-full .hint {
    position: absolute;
    right: 10vh;
    width: 10vh;
    height: 4.8vh;
    bottom: 10vh;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    border-radius: 0.5vh;
    border: 0;
    cursor: pointer;
    line-height: 4.8vh;
    text-transform: uppercase; }
    .assignment-full .hint svg {
      font-size: 2vh;
      vertical-align: -0.5vh;
      margin-right: 1vh; }
  .assignment-full .confirm {
    background-color: #22246e;
    color: #fff;
    position: absolute;
    top: 30vh;
    height: 26vh;
    width: 60vh;
    right: 10vh;
    padding: 4vh;
    border-radius: 1vh; }
    .assignment-full .confirm button {
      line-height: 4vh;
      width: 23vh;
      border: 1px solid #fff;
      border-radius: 0.5vh;
      background-color: transparent;
      color: #fff;
      text-transform: uppercase;
      font-size: 1.4vh;
      margin-top: 4vh;
      cursor: pointer; }
      .assignment-full .confirm button.yes {
        background-color: #fff;
        color: #22246e;
        float: right; }
    .assignment-full .confirm h2 {
      padding-bottom: 2vh; }
    .assignment-full .confirm p {
      line-height: 3vh; }

.marker {
  width: 2vh;
  height: 2vh;
  margin: -1vh 0 0 -1vh;
  background-color: #2d2e82;
  border-radius: 1.5vh;
  opacity: 0.8; }
  .marker span {
    position: absolute;
    left: 1.4vh;
    line-height: 2vh;
    display: block;
    background-color: #fff;
    padding: 0 0.5vh;
    border-radius: 0.5vh;
    font-size: 1.2vh;
    white-space: nowrap; }
